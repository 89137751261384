<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div
            class="card-body "
            style=" padding: 1.2rem;"
          >
            <div class="mb-3">
              <h3>
                ช่องทางการซื้อ-ขาย
              </h3>
            </div>
            <div class="row">
              <b-col cols="12">
                <validation-observer ref="channelRules">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-group
                      label="ช่องทาง"
                      label-for="h-first-name"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="inputs.channel"
                        :state="errors.length > 0 ? false:null"
                        placeholder="กรอกช่องทาง"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >กรุณากรอกช่องทางการขาย</small>
                    </b-form-group>
                  </validation-provider>
                </validation-observer>
              </b-col>
              <b-col cols="12 mb-2">

                <b-button
                  :variant="inputs.id===''?'primary':'warning'"
                  class="mr-1"
                  @click="onClickCommitChanel"
                >
                  {{ inputs.id === ''?'เพิ่ม':'แก้ไข' }}
                </b-button>
              </b-col>
            </div>
            <div class="">
              <b-table
                :sticky-header="true"
                :no-border-collapse="false"
                responsive
                :items="itemschannel"
                :fields="fieldsChannel"
                class="mb-0"
              >
                <template #cell(name)="data">
                  {{ data.item.name }}
                  <!-- <p>{{ data.item.price.$numberDecimal | toCurrency }}</p> -->
                </template>
                <template #cell(button)="data">
                  <div
                    v-if="data.item.name.trim() !== 'HDAY Shopping'"
                    class="d-flex"
                  >
                    <button
                      class="btn btn-sm btn-warning mr-1"
                      type="button"
                      @click="onClickEditChannel(data.item._id,data.item.name)"
                    >
                      แก้ไข
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      type="button"
                      @click="deleteChannel(data.item._id)"
                    >
                      ลบ
                    </button>
                  </div>
                </template>
              </b-table>
            </div>

          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div
            class="card-body "
            style=" padding: 1.2rem;"
          >
            <div class="mb-3">
              <h3>
                จัดการตำแหน่งเจ้าหน้าที่
              </h3>
            </div>
            <div class="row">
              <b-col cols="12">
                <validation-observer ref="positionRules">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-group
                      label="ตำแหน่ง"
                      label-for="h-first-name"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="inputsEmployeePosition.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="กรอกตำแหน่ง"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >กรุณากรอกตำแหน่งพนักงาน</small>
                    </b-form-group>
                  </validation-provider>
                </validation-observer>
              </b-col>

              <b-col cols="12 mb-2">
                <b-button
                  :variant="inputsEmployeePosition.id===''?'primary':'warning'"
                  class="mr-1"
                  @click="onClickCommitPosition"
                >
                  {{ inputsEmployeePosition.id === ''?'เพิ่ม':'แก้ไข' }}
                </b-button>
              </b-col>
            </div>
            <div class="">
              <b-table
                :sticky-header="true"
                :no-border-collapse="false"
                responsive
                :items="itemsPosition"
                :fields="fieldsPosition"
                class="mb-0"
              >
                <template #cell(name)="data">
                  {{ data.item.name }}
                  <!-- <p>{{ data.item.price.$numberDecimal | toCurrency }}</p> -->
                </template>
                <template #cell(button)="data">
                  <div class="d-flex">
                    <button
                      class="btn btn-sm btn-warning mr-1"
                      type="button"
                      @click="onClickEditPosition(data.item._id,data.item.name)"
                    >
                      แก้ไข
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      type="button"
                      @click="deletePosition(data.item._id)"
                    >
                      ลบ
                    </button>
                  </div>
                </template>
              </b-table>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div
            class="card-body "
            style=" padding: 1.2rem;"
          >
            <div class="mb-3">
              <h3>
                บัญชีธนาคารทางร้าน
              </h3>
            </div>
            <div class="row">
              <div class="col-sm-6 col-12">
                <validation-observer ref="accountRules">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-group
                      label="ชื่อบัญชีธนาคาร"
                      label-for="h-first-name"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="inputsAccount.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="ชื่อบัญชีธนาคาร"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >กรุณากรอกช่องทางการขาย</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="bankName"
                    rules="required"
                  >
                    <b-form-group
                      label="บัญชีธนาคาร"
                      label-for="h-first-name"
                      label-cols-md="4"
                    >
                      <b-form-select
                        v-model="inputsAccount.bankName"
                        :options="opptionBank"
                        :state="errors.length > 0 || inputsAccount.bankName === null && checkAccountSelect !== true ? false:null"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >กรุณาเลือกธนาคาร</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="bankNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="เลขที่บัญชี"
                      label-for="h-first-name"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="inputsAccount.bankNumber"
                        :state="errors.length > 0 ? false:null"
                        placeholder="ชื่อบัญชีธนาคาร"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >กรุณากรอกช่องทางการขาย</small>
                    </b-form-group>
                  </validation-provider>
                </validation-observer>
              </div>
              <b-col cols="12 mb-2">

                <b-button
                  :variant="inputsAccount.id===''?'primary':'warning'"
                  class="mr-1"
                  @click="onClickCommitAccount"
                >
                  {{ inputsAccount.id=== ''?'เพิ่ม':'แก้ไข' }}
                </b-button>
              </b-col>
            </div>
            <div class="">
              <b-table
                :sticky-header="true"
                :no-border-collapse="false"
                responsive
                :items="itemAccount"
                :fields="fieldsAccount"
                class="mb-0"
              >
                <template #cell(name)="data">
                  {{ data.item.name }}
                  <!-- <p>{{ data.item.price.$numberDecimal | toCurrency }}</p> -->
                </template>
                <template #cell(bankName)="data">
                  {{ data.item.bankName | nameAccount }}
                </template>
                <template #cell(button)="data">
                  <div class="d-flex">
                    <button
                      class="btn btn-sm btn-warning mr-1"
                      type="button"
                      @click="onClickEditAccount(data.item._id,data.item.name,data.item.bankName,data.item.bankNumber)"
                    >
                      แก้ไข
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      type="button"
                      @click="deleteAccount(data.item._id)"
                    >
                      ลบ
                    </button>
                  </div>
                </template>
              </b-table>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput,
  BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import data_bank from '@/data-json/bank.json'

export default defineComponent({
  name: 'setting',
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required
  },
  watch: {
    'inputs.channel': {
      handler (val) {
        if (val === '') {
          this.inputs.id = ''
        }
      }
    }
  },
  data () {
    return {
      inputs: {
        id: '',
        channel: ''
      },
      inputsAccount: {
        id: '',
        name: '',
        bankNumber: '',
        bankName: '',
        bankDetail: ''

      },
      checkAccountSelect: true,

      opptionBank: [],

      inputsEmployeePosition: {
        id: '',
        name: ''
      },
      fieldsPosition: [
        {
          key: 'name', label: 'ชื่อตำแหน่ง'
        },
        { key: 'button', label: '', thStyle: 'width: 100px', tdClass: 'text-center', thClass: 'text-center' }

      ],
      itemschannel: [],
      itemsPosition: [],
      itemAccount: [],
      fieldsChannel: [
        {
          key: 'name', label: 'ชื่อช่องทาง'
        },
        { key: 'button', label: '', thStyle: 'width: 150px', tdClass: 'text-center', thClass: 'text-center' }

      ],
      fieldsAccount: [
        {
          key: 'name', label: 'ชื่อบัญชีธนาคาร', thStyle: 'min-width: 150px'
        },
        {
          key: 'bankName', label: 'บัญชีธนาคาร', thStyle: 'min-width: 150px'
        },
        {
          key: 'bankNumber', label: 'เลขที่บัญชี', thStyle: 'min-width: 150px'
        },
        { key: 'button', label: '', thStyle: 'width: 150px', tdClass: 'text-center', thClass: 'text-center' }

      ]
    }
  },
  computed: {

  },
  methods: {
    loadBank () {
      this.opptionBank = [{ value: '', text: 'โปรดเลือกธนาคาร' }]
      data_bank.RECORDS.forEach(data => {
        this.opptionBank.push({ value: data.code, text: data.name })
      })
    },
    onClickEditChannel (id, name) {
      this.inputs.id = id
      this.inputs.channel = name
    },
    onClickEditPosition (id, name) {
      this.inputsEmployeePosition.id = id
      this.inputsEmployeePosition.name = name
    },
    onClickEditAccount (id, name, bankName, bankNumber) {
      this.inputsAccount = {
        id,
        name,
        bankNumber,
        bankName,
        bankDetail: ''

      }
    },
    loader () {
      this.$store.dispatch('setting/getAllChannel').then(result => {
        this.itemschannel = result.data.items
        this.inputs.channel = ''
        this.$refs.channelRules.reset()
      })
      this.$store.dispatch('setting/getPosition').then(result => {
        this.itemsPosition = result.data.items
        this.$refs.positionRules.reset()
      })

      this.$store.dispatch('setting/getAccount').then(result => {
        console.log('pppp', result)

        this.itemAccount = result.data.items
        this.$refs.accountRules.reset()
      })
      this.reNewData()
      this.loadBank()
    },
    onClickCommitChanel () {
      if (this.inputs.id === '') {
        this.addChannel()
      } else {
        this.editChanel()
      }
    },
    onClickCommitPosition () {
      if (this.inputsEmployeePosition.id === '') {
        this.addPosition()
      } else {
        this.editPosition()
      }
    },
    onClickCommitAccount () {
      if (this.inputsAccount.id === '') {
        this.addAccountBank()
      } else {
        this.editAccountBank()
      }
    },
    editChanel () {
      this.$refs.channelRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('setting/editChannel', { id: this.inputs.id, name: this.inputs.channel })
            .then(response => {
              console.log(response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ช่องทาง',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.loader()
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ช่องทาง',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    editPosition () {
      this.$refs.positionRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('setting/editPosition', { id: this.inputsEmployeePosition.id, name: this.inputsEmployeePosition.name })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ช่องทาง',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.loader()
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ช่องทาง',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    addPosition () {
      this.$refs.positionRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('setting/addPosition', { name: this.inputsEmployeePosition.trim() })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ตำแหน่ง',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.loader()
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ตำแหน่ง',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    addChannel () {
      this.$refs.channelRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('setting/addChannel', { name: this.inputs.channel })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ช่องทาง',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.loader()
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ช่องทาง',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    deletePosition (id) {
      this.$store
        .dispatch('setting/deletePosition', { id })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ตำแหน่ง',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message
            }
          })
          this.loader()
        }).catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ตำแหน่ง',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.data.message
            }
          })
        })
    },
    deleteChannel (id) {
      this.$store
        .dispatch('setting/deleteChannel', { id })
        .then(response => {
          console.log(response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ช่องทาง',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message
            }
          })
          this.loader()
        }).catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ช่องทาง',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.data.message
            }
          })
        })
    },
    deleteAccount (id) {
      this.$store
        .dispatch('setting/deleteAccount', { id })
        .then(response => {
          console.log(response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'บัญชี',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message
            }
          })
          this.loader()
        }).catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'บัญชี',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.data.message
            }
          })
        })
    },
    reNewData () {
      this.inputs.id = ''
      this.inputs.channel = ''
      this.inputsEmployeePosition.id = ''
      this.inputsEmployeePosition.name = ''
      this.inputsAccount = {
        id: '',
        name: '',
        bankNumber: '',
        bankName: '',
        bankDetail: ''

      }
      // this.$refs.positionRules.reset()
      // this.$refs.channelRules.reset()
    },
    addAccountBank () {
      this.$refs.accountRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('setting/addAccount', this.inputsAccount)
            .then(response => {
              console.log('000', response)

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'บัญชี',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.loader()
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'บัญชี',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    editAccountBank () {
      this.$refs.accountRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('setting/editAccount', this.inputsAccount)
            .then(response => {
              console.log(response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'บัญชี',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.loader()
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'บัญชี',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    }
  },
  created () {
    this.loader()
  }
})
</script>
